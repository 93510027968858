<template>
  <div id="reports">
    <div class="tRowTopMain" :class="{tRowTopMainnonMobile : isMobile(true) }">
        <MiniMenu
         :config="config"
         :title="title"/>
    </div>
    <b-container class="topPad">
      <b-row>
        <b-col lg="8" md="12">
          <b-row class="ShowMobile">
            <b-col>
              <b-img class="tournHeadLogoMob" :src="tmparams.sponsor_logo"></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h3 class="tournHead">
                {{ tmparams.full_name }}
              </h3>
              <h3 class="tournDates">
                {{ tmparams.dates }}
              </h3>
              <h3 class="tournDates">
                {{ tmparams.course_name }}
              </h3> 
            </b-col>
          </b-row>
          <b-row style="padding-top:20px;">
            <b-col>
              <template v-if="tmparams.winner_found === 'Y'">
                <h3 class="reportInfo">
                  {{ tmparams.winner_name }}
                </h3>
                <h3 class="tournDates">
                  Champion
                </h3>
              </template>
              <template v-if="tmparams.winner_found === 'N'">
                <h3 class="reportInfo" v-if="tmparams.previous_winners.previous_winners_entry.cc_name_1">
                  {{
                    tmparams.previous_winners.previous_winners_entry.cc_name_1
                  }}
                </h3>
                <h3 class="reportInfo" v-else>
                    N/A
                </h3>
                <h3 class="tournDates">
                  Defending Champion
                </h3>
              </template>
            </b-col>
            <b-col>
              <h3 class="reportInfo">
                {{ tmparams.total_prize_fund }}
              </h3>
              <h3 class="tournDates ">
                Purse
              </h3>
            </b-col>
          </b-row>
          <b-row
            class="tabRow"
            v-if="
              title === 'Scores Round ' + this.data.pft_round &&
                this.data.match_play === 'N'
            "
          >
            <b-col class="NoPad NopadLeft">
              <b-button
                class="tabBtn"
                :class="{ active: isHidden === '1' }"
                v-on:click="isHidden = '1'"
              >
                Leaderboard
              </b-button>
            </b-col>
            <b-col v-if="teamEvent === 'Y'" class="NoPad">
              <b-button
                class="tabBtn"
                :class="{ active: isHidden === '3' }"
                v-on:click="isHidden = '3'"
              >
                Team
              </b-button>
            </b-col>
            <b-col class="NoPad">
              <b-button
                class="tabBtn"
                :class="{ active: isHidden === '2' }"
                v-on:click="isHidden = '2'"
              >
                Hole-by-Hole
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-img class="tournHeadLogo" :src="tmparams.sponsor_logo"></b-img>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="tmparams.multi_course === 'Y'" lg="12" class="multi ShowMobile">
          <p class="coursesP">
            <span>Courses:</span>
          </p>
          <span
            class="multiList"
            v-for="(multi, index) in tmparams.courses.courses_entry"
            :key="index"
          >
            <span :class="multi.course_X_colour">•</span
            ><span class="multiName">{{ multi.course_X_id }}</span>
          </span>
        </b-col>
        <b-col lg="12" cols="12" :class="{colWidth: tmparams.multi_course === 'Y'}" class="ShowMobile">
          <b-row>
            <b-col>
              <b-media :class="{mediaNone :  tmparams.multi_course === 'Y'}">
                <template #aside>
                  <b-img
                    width="50"
                    vertical-align="center"
                    :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"
                  ></b-img>
                </template>
                <p class="BallUsage">
                  = *Signifies Titleist golf ball usage
                </p>
              </b-media>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-media :class="{mediaNone :  tmparams.multi_course === 'Y'}">
                <template #aside>
                  <b-img
                    width="34"
                    :src="config.VUE_APP_ASSETS+'2020/04/Theauer.png'"
                    alt="placeholder"
                  ></b-img>
                </template>
                <p class="rookieYear">
                  - eligible for TAG Heuer Rookie of the Year
                </p>
              </b-media>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="topPad">
        <b-col lg="6" md="6" cols="12" :class="{colWidth: tmparams.multi_course === 'Y'}">
          <div :class="drop">
            <b-nav card-header pills>
              <b-dropdown
                v-if="Array.isArray(tmparams.reports.reports_entry)"
                :text="title"
              >
                <b-dropdown-item
                  v-on:click="changeReport"
                  v-for="(reports, index) in filterExemptions"
                  :key="index"
                  :title="reports.report_url"
                  :value="reports.report_title"
                  v-show="!reports.report_title.includes('(Team)')"
                  >{{ reports.report_title.replace("(Pro)", "") }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown v-else text="Tournament Entries">
                <b-dropdown-item
                  v-on:click="changeReport"
                  v-for="(reports, index) in tmparams.reports"
                  :key="index"
                  :title="reports.report_url"
                  v-show="reports.report_title !== 'Scoring Statistics' && reports.report_title !== 'Course Statistics' && reports.report_title !== 'Course Statistics (COR)' && reports.report_title !== 'Course Statistics (REM)' && reports.report_title !== 'Statistics Tables'"
                  >{{ reports.report_title }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </div>
        </b-col>
        <b-col v-if="tmparams.multi_course === 'Y'" lg="4" class="multi hideMobile">
          <p class="coursesP">
            <span>Courses:</span>
          </p>
          <span
            class="multiList"
            v-for="(multi, index) in tmparams.courses.courses_entry"
            :key="index"
          >
            <span :class="multi.course_X_colour">•</span
            ><span class="multiName">{{ multi.course_X_id }}</span>
          </span>
        </b-col>
        <b-col lg="6" cols="12" :class="{colWidth: tmparams.multi_course === 'Y'}" class="hideMobile">
          <b-row>
            <b-col>
              <b-media :class="{mediaNone :  tmparams.multi_course === 'Y'}">
                <template #aside>
                  <b-img
                    width="50"
                    vertical-align="center"
                    :src="'https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/titleist_oom-copy.png'"
                  ></b-img>
                </template>
                <p class="BallUsage">
                  = *Signifies Titleist golf ball usage
                </p>
              </b-media>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-media :class="{mediaNone :  tmparams.multi_course === 'Y'}">
                <template #aside>
                  <b-img
                    width="34"
                    :src="config.VUE_APP_ASSETS+'2020/04/Theauer.png'"
                    alt="placeholder"
                  ></b-img>
                </template>
                <p class="rookieYear">
                  - eligible for TAG Heuer Rookie of the Year
                </p>
              </b-media>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="Object.keys(data).length === 0">
        <b-row>
          <b-col>
            <h2 class="noData">
              No Data
            </h2>
          </b-col>
        </b-row>
      </div>
      <div v-else class="reports">
          <div
            v-if="
              this.currentReport.indexOf('tmentry') !== -1 ||
                this.currentReport.indexOf('tmdraw') !== -1 ||
                this.currentReport.indexOf('tmresult') !== -1 ||
                this.currentReport.indexOf('tmtsum') !== -1 ||
                this.currentReport.indexOf('tmoomt') !== -1
            "
          >
            <ReportTable
              :data="data"
              :home="home"
              :previousData="previousData"
              :title="title"
              :season="tmparams.tm_params.season_code"
              :code="tmparams.code"
              :tour="this.$route.query.tour"
              :team="'N'"
              :config="config"
              :match="tmparams.match_play"
            />
          </div>
          <div
            class="main"
            v-else-if="this.currentReport.indexOf('tmscore') !== -1"
          >
            <template v-if="data.match_play == 'N'">
              <b-tabs content-class="mt-3">
                <b-tab
                  title="Leaderboard"
                  active
                  title-item-class="hideLeadertab"
                >
                  <ReportTable
                    :data="data"
                    :home="home"
                    :previousData="previousData"
                    :title="title"
                    :season="tmparams.tm_params.season_code"
                    :code="tmparams.code"
                    :tour="this.$route.query.tour"
                    :team="'N'"
                    :config="config"
                    :match="tmparams.match_play"
                  />
                </b-tab>
                <b-tab title="Team" v-if="teamEvent === 'Y'">
                  <ReportTable
                    :data="data"
                    :home="home"
                    :previousData="previousData"
                    :title="title"
                    :season="tmparams.tm_params.season_code"
                    :code="tmparams.code"
                    :tour="this.$route.query.tour"
                    :team="teamEvent"
                    :config="config"
                    :match="tmparams.match_play"
                  />
                </b-tab>
                <b-tab title="Hole-by-Hole" title-item-class="hideHBH">
                  <HBH
                    :data="data"
                    :scores="data.scores.scores_entry"
                    :previousData="previousData"
                    :config="config"
                  />
                </b-tab>
              </b-tabs>
            </template>
            <template v-else>
              <ReportTable
                :data="data"
                :home="home"
                :previousData="previousData"
                :title="title"
                :season="tmparams.tm_params.season_code"
                :code="tmparams.code"
                :tour="this.$route.query.tour"
                :team="'N'"
                :config="config"
                :match="tmparams.match_play"
              />
            </template>
          </div>
        </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import MiniMenu from "@/components/miniMenu.vue";
import ReportTable from "@/components/reportTable.vue";
import HBH from "@/ocs/hbh.vue";

export default {
  name: "Reports",
  props:['config'],
  components: {
    MiniMenu,
    ReportTable,
    HBH,
  },
  data() {
    return {
      data: [],
      previousData: [],
      tmparams: [],
      drop: "drop",
      currentReport: this.$route.query.url,
      title: this.$route.query.title,
      home: false,
      tabs: true,
      isHidden: "1",
    };
  },
  methods: {
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
    changeReport: function(event) {
      var report = event.target.getAttribute("title");
      var title = event.target.getAttribute("value");
      this.title = title;
      return (
        (this.currentReport = report),
        axios
          .get(this.reportTitle)
          .then(response => (this.data = response.data))
      );
    },
    updateStuff: function() {
      axios
        .get(this.reportTitle)
        .then((response) => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              this.$route.query.tour +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then((response) => {
          this.tmparams = response.data;
        });
    },
  },
  computed: {
    teamEvent:function(team){
      var array = this.data
      if(array['team_scores'] !== undefined)
      team = 'Y'
      else
      team = 'N'
      return team
    },
    filterExemptions: function() {
      return this.tmparams.reports.reports_entry.filter(
        reports_entry =>
          !reports_entry.report_title.indexOf("Tournament Entries") ||
          !reports_entry.report_title.indexOf("Round 1 Draw") ||
          !reports_entry.report_title.indexOf("Round 2 Draw") ||
          !reports_entry.report_title.indexOf("Round 3 Draw") ||
          !reports_entry.report_title.indexOf("Round 4 Draw") ||
          !reports_entry.report_title.indexOf("Round 5 Draw") ||
          !reports_entry.report_title.indexOf("Round 6 Draw") ||
          !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
          !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
          !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
          !reports_entry.report_title.indexOf("Final Result")
        // !reports_entry.report_title.indexOf("Live Scoring Monitor")
      );
    },
    reportTitle: function(url) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_TIC_BASE +
          this.$route.query.tour +
          "/" +
          this.$route.query.id +
          "/" +
          this.$route.query.id +
          "-" +
          this.$route.query.code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscore") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
             process.env.VUE_APP_TIC_BASE +
              this.$route.query.tour +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.$route.query.tour +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1) {
          {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.$route.query.tour +
              "/" +
              this.$route.query.id +
              "/" +
              this.$route.query.id +
              "-" +
              this.$route.query.code +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            process.env.VUE_APP_TIC_BASE +
            this.$route.query.tour +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            process.env.VUE_APP_TIC_BASE +
            this.$route.query.tour +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            process.env.VUE_APP_TIC_BASE +
            this.$route.query.tour +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    axios
      .get(this.reportTitle)
      .then((response) => {
        this.data = response.data;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            this.$route.query.tour +
            "/" +
            this.$route.query.id +
            "/" +
            this.$route.query.id +
            "-" +
            this.$route.query.code +
            "-tmticx.json?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.tmparams = response.data;
      });
  },
};
</script>

<style scoped>
.tRowTopMainnonMobile {
  background-color: transparent!important;
}
.tRowTopMain {
  background-color: rgba(0, 0, 0, 0.04);
}
.col-lg-6.col-12.colWidth {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
::v-deep .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 0px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #000 !important;
}
::v-deep .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #34cca9;
  border: 0px !important;
  color: #fff !important;
}
.media {
  margin-left: 16em;
}
.mediaNone {
  margin-left: 0em;
}
::v-deep .dropdown-item {
  color: #003C52!important;
}
.reportInfo {
  font-weight: 600;
  font-size: 1.5rem;
  color: #00b588;
}
.coursesP {
  margin-bottom: 0px;
}
::v-deep .NopadLeft {
  padding-left: 15px!important;
}
::v-deep .NoPad {
  padding: 0px;
}
::v-deep button.btn.tabBtn.btn-secondary {
  height: 38px;
  padding-top: 7px;
  padding-bottom: 10px;
}
::v-deep button.btn.tabBtn.btn-secondary.active {
  height: 38px;
  padding-top: 7px;
  padding-bottom: 10px;
}
::v-deep button#__BVID__32__BV_toggle_ {
  line-height: 35px;
  padding-bottom: 0;
}
.BallUsage {
  font-size: 10pt;
  font-weight: bold;
}
.rookieYear {
  font-size: 10pt;
  font-weight: bold;
  margin-top: 8px;
}
#reports {
  /* background-color: #f9f9f9; */
}
.topPad {
  padding-top: 50px;
}
.titleMainTour {
  color: #000;
  margin: 0;
  font-size: 10pt;
  font-weight: 700;
  padding-left: 10px;
  line-height: 2;
}
li {
  list-style-type: none;
}
.menuLink {
  text-align: right;
}
.menuLink > a {
  padding: 4px 0 0 3em;
  text-transform: capitalize;
  font-size: 10pt;
  font-weight: 500;
}
.tRow {
  border-bottom: 1px solid #000;
  padding: 10px 0;
}
.multi {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 600;
}
.multiList {
  /* padding: 0 10px; */
}
.multiName {
  color: #000000 !important;
  position: relative !important;
  top: -14px !important;
  /* padding-left: 17px; */
  padding-right: 10px;
  font-size: 14px !important;
}
.multiList > span {

}
.tournDates {
  text-align: left;
  color: #000;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 100;
  font-size: 16px;
  font-weight: 600;
}
.tournHead {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 600;
  font-family: 'Gantari', sans-serif !important;
  color: #000;
  font-size: 50px;
}
.tournHeadLogo {
  max-width: 323px;
  display: block;
  padding-bottom: 10px;
  width: 150px;
  float: right;
}
.tournHeadLogoMob {
  max-width: 323px;
  display: none;
  padding-bottom: 10px;
  width: 200px;
}
.ShowMobile.col-lg-12.col-12 {
  padding-top: 30px;
  padding-bottom: 25px;
}
.imob {
  border: none;
  width: 240px;
  height: 125px;
}
.tabRow {
  padding: 0;
  padding-top: 20px;
  width: 400px;
}
.tabBtn {
  width: 100%;
  color: #0c4c39;
  background-color: #e6e6e6;
  border: none;
  /* padding: 10px 0; */
  text-align: center;
}
.active {
  color: white !important;
  /* background-color: #00b588 !important; */
}
::v-deep tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
}
::v-deep .drop {
  padding-left: 10px;
  padding-bottom: 2%;
}
.sponsor {
  margin-top: -10%;
  width: 20%;
  padding-left: 10%;
}

.nav-link {
  color: black !important;
  width: 100%;
  font-size: 18pt;
}

::v-deep .mt-3 {
  margin-top: 0 !important;
}
.B {
  color: #333333;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.Y {
  color: #d8db22;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.C {
  color: #1E90FF;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.R {
  color: #BB0000;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.G {
  color: #3c9933;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}
.O {
  color: #e07020;
    font-size: 43px !important;
  line-height: 1.2;
  vertical-align: bottom !important;
}

.noData {
  text-align: center;
  padding: 70px;
  background-color: #00433e;
  color: white;
}
::v-deep button {
  color: black;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 0;
  width: 250px;
  text-align: left;
  line-height: 26px;
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
::v-deep button::after {
  float: right;
  margin-top: 10px;
}
::v-deep button:hover {
  color: #003C52;
  background-color: white;
}

@media only screen and (min-width: 769px) {
  .ShowMobile {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
    width: 100%;
    display: block;
  }
  ::v-deep button {
    width: 100%!important;
  }
  .tournDates {
    text-align: left;
    color: #000;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 100;
    font-size: 14px;
    font-weight: 600;
  }
  .tournHeadLogo {
    display: none;
  }
  .media {
    margin-left: 0;
  }
  .hideMobile {
    display: none;
  }
  .topPad {
    padding-top: 0px;
  }
  .mobHide {
    display: none;
  }
  .tournHead {
    text-align: left;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 600;
    font-family: 'Gantari', sans-serif !important;
    color: #000;
    font-size: 33px;
  }
}
@media only screen and (max-width: 480px) {
  .sponsor {
    margin-top: 2%;
    width: 35%;
    padding-left: 4%;
  }
}
</style>
