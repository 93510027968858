<template>
  
    <b-container>
      <b-row class="MobMiniMen">
        <b-col lg='8' md='4' cols='12' class="titleMini-sec">
          <span class="FirstBreadCrum">
            <template v-if="$route.name == 'Schedule'">
              <b-link :href="firstCrumUrl" class="crumLinks">
                {{FirstCrum }} Schedule
              </b-link>
            </template>
            <template v-else>
              <!-- Schedule > Tournament > Reports -->
              <b-link :href="firstCrumUrl" class="crumLinks">
                {{FirstCrum }} Schedule
              </b-link>
              <span>
                <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
              </span>
              <template v-if="$route.name == 'tournament'">
                <span class="secondScrum">
                  <b-link :href="tournamentCrum" class="crumLinks">
                    <template>{{TournmentName}}</template> 
                  </b-link>
                </span> 
              </template>
              <template v-else-if="$route.name == 'reports'">
                <span class="secondScrum">
                  <b-link :href="TournmentLink" class="crumLinks">
                    <template>{{TournmentName}}</template> 
                  </b-link>
                </span> 
                <span >
                  <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                </span>
                <span class="secondScrum">
                  <b-link :href="'/'" class="crumLinks">
                    <template >{{title}}</template>
                  </b-link>
                </span> 
              </template>
              <template v-else-if="$route.name == 'playerprofile'">
                <span class="secondScrum">
                  <b-link :href="'/'" class="crumLinks">
                    <template>Profiles</template> 
                  </b-link>
                </span> 
                <span >
                  <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                </span>
                <span class="secondScrum">
                  <b-link :href="PlayerLink" class="crumLinks">
                    <template >{{PlayerName}}</template>
                  </b-link>
                </span> 
              </template>
              <template v-else>
                <span class="secondScrum" v-if="clearData()">
                  <b-link :href="TournmentLink" class="crumLinks">
                    <template>{{TournmentName}}</template> 
                  </b-link>
                </span> 
                <span v-if="clearData()">
                  <img class="arrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png">
                </span>
                <span class="secondScrum">
                  <template v-if="SecondCrum == 'oom'">
                    <b-link
                      :to="{
                        name: 'oom',
                        query: { id: seasonCode , tour: tour  },
                      }"
                      class="crumLinks"
                    >
                      <template>Order of Merit</template>
                    </b-link>
                  </template>
                  <template v-else>
                    <b-link :href="secondCrumUrl" class="crumLinks">
                      <template>{{SecondCrum}}</template>
                    </b-link>
                  </template>
                </span> 
              </template>
            </template>
          </span>
        </b-col>
        <template v-if="$route.name == 'reports'">
          <b-col lg='4' md='4' cols='12'>
            <b-nav-item
              class="menuLink"
              :to="'/'"
              >Schedule</b-nav-item
            >
          </b-col>
        </template>
        <template v-else>
          <b-col lg='2' md='2' cols='6'>
            <b-nav-item
              class="menuLink"
              :to="'/'"
              >Schedule</b-nav-item
            >
          </b-col>
          <b-col lg='2' md='2' cols='6'>
            <b-nav-item
              class="menuLink"
              :to="{
                name: 'oom',
                query: { id: seasonCode , tour: tour  },
              }"
              >Order of Merit</b-nav-item
            >
          </b-col>
        </template>
        <!-- <b-col lg='1' md='2' cols='4'>
          <b-nav-item class="menuLink" to="/news">News</b-nav-item>
        </b-col> -->
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: "miniMenu",
  props:['config', 'seasonCode', 'tour', 'tournamentName', 'title'],
  data() {
    return {
      FirstCrum: process.env.VUE_APP_BREAD_CRUM_NAME,
      firstCrumUrl: process.env.VUE_APP_FIRST_CRUM_URL,
      tournamentCrum: this.$route.fullPath,
      SecondCrum : this.$route.name,
      secondCrumUrl: this.$route.path,
      TournmentName: sessionStorage.getItem("Tournament Name"),
      TournmentLink: sessionStorage.getItem("Tournament Link"),
      PlayerName: sessionStorage.getItem("Player Name"),
      PlayerLink: sessionStorage.getItem("Player Link")
    }
  },
  methods:{
    clearData() {
      if (this.$route.name == 'oom') {
        sessionStorage.clear('Tournament Name');
      } 
    }
  },
  mounted() {
    this.clearData();
  }
};
</script>

<style scoped>
img.arrow {
    width: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
a.crumLinks {
  color: #000!important;
  text-transform: capitalize;
}
a.crumLinks:hover {
  color: #00b588!important;
  text-decoration: none;
}
/* span.secondScrum:before {
  content: url('https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/08/Screenshot-2024-08-08-114110-copy.png');
  width: 10px;
} */
span.FirstBreadCrum {
  font-size: 14px;
  position: relative;
  top: 2px;
}
.titleMini-sec {
  /* padding-left: 15px; */
  padding-left: 0px;
}
.tRow {
  border-bottom: 0px solid #000;
  padding: 15px 0;
  background: rgba(0, 0, 0, 0.04);
}
.titleMainTour {
  color: #000;
  margin: 0;
  font-size: 10pt;
  font-weight: 500;
  line-height: 2;
  position: relative;
  top: 3px;
}
li {
  list-style-type: none;
}
.menuLink {
  text-align: right;
  position: relative;
  top: 0px;
  width: 100% !important;
}
.menuLink > a {
  /* padding: 4px 0 0 3em; */
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: black!important;
}
.menuLink > a:hover {
  /* padding: 4px 0 0 3em; */
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: #00b588!important;
}
.tourLogo {
  max-width: 80px !important;
  margin-top: -3px;
  padding-left: 0;
  margin-left: 15px;
  max-height: 34px !important;
  width: initial !important;
}
@media only screen and (max-width: 768px) {
  ::v-deep .menuLink > a {
    padding: 0px;
    text-transform: capitalize;
    font-size: 10pt;
    font-weight: 500;
    color: black!important;
    padding-bottom: 10px;
  }
  ::v-deep .menuLink {
    text-align: left;
    position: relative;
    top: 7px;
  }
  ::v-deep .MobMiniMen {
    display: block;
    text-align: left;
  }
  ::v-deep .titleMini-sec {
    padding-left: 15px;
    width: 100%;
    max-width: 100%;
    flex: 100%;
    padding-bottom: 10px;
  }
  ::v-deep .card {
    height: 250px;
    width: 100%;
  }
}
</style>
