<template>
  <div>
    <b-container>
      <div class="ad" style="text-align:center; height: 150px; padding-top:30px">
        <Ads :tour="tour"/>
      </div>
      <div class="content">
        <b-jumbotron
          class="jumbo"
          header="Find a PGA Pro"
          lead="Give your game a helping hand"
        >
          <div>
            <input type="text" v-model="selected" list="my-list-id" />

            <datalist id="my-list-id">
              <option v-for="(pcode, index) in items" :key="index"
                >{{ pcode.locality }}, {{ pcode.postcode }}</option
              >
            </datalist>
            <b-button class="find" @click="go()">Search</b-button>
          </div>
        </b-jumbotron>
      </div>
      <div class='mapCont'>
      <GmapMap
        :center="center"
        :zoom="zoom"
        style="width:100%;  height: 400px;"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in pros.clubs"
          :position="{
            lat: Number(m.location.coordinates[1]),
            lng: Number(m.location.coordinates[0]),
          }"
          @click="toggleInfoWindow(m, index)"
        />
        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <div v-html="infoContent"></div>
        </gmap-info-window>
      </GmapMap>
      <div v-if="pros === ''"></div>
      <div v-else>
        <ProTable :pros="filter" />
      </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import Ads from "@/components/ads.vue";
import ProTable from "@/components/proTable.vue";
export default {
  name: "findPro",
  components: {
    ProTable,
    Ads
  },

  data() {
    return {
      // tour: this.$route.query.tour,
      center: { lat: -23.3037127, lng: 134.0974459 },
      zoom: 4,
      items: [],
      selected: "",
      filter:'',
      pros: "",
      state: "",
      rad: "15",
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  computed: {
    filterSub: function(select) {
      select = this.selected.split(",");
      if (this.selected === "") return "N";
      else
        return this.items.filter((code) => !code.locality.indexOf(select[0]));
    },
  },
  methods: {
    setName(value) {
      var lat = value[0];
      var lng = value[1];
      return { lat, lng };
    },
    toggleInfoWindow: function(marker, idx) {
      var latST = marker.location.coordinates[1];
      var longST = marker.location.coordinates[0];
      var lat = parseFloat(latST);
      var lng = parseFloat(longST);
      var center = { lat, lng };
      this.infoWindowPos = center;
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent: function(marker) {
      this.filter = this.pros.clubs.filter((pro) => pro.vname === marker.vname)
      return `<div>
              <p class="title is-4">${marker.vname}</p>
              <p> PGA Pros: ${this.filter.length}</p>
            </div>`;
    },
        proFilter:function(marker){
      return this.pros.clubs.filter( (pro) => pro.vname === marker.vname)
    },
    go: function() {
      var latST = this.filterSub[0].lat;
      var longST = this.filterSub[0].long;
      var lat = parseFloat(latST);
      var lng = parseFloat(longST);
      this.center = { lat, lng };
      return (
        axios //add API Call
          .get(
            "https://pga.org.au/api/pgaaus/findapro?field=Y&q_long=" +
              this.center.lng +
              "&q_lat=" +
              this.center.lat +
              "&q_dist=" +
              this.rad +
              "&randomadd=" +
              new Date().getTime()
          ) // Page number changed on each page based on JSON page id you are calling
          .then((response) => (this.pros = response.data, this.filter = response.data.clubs)),
        (this.zoom = 11)
      );
    },
  },
  mounted() {
    axios //add API Call
      .get("https://pga.org.au/api/pgaaus/postcode?field=Y&q=") // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.items = response.data.postcodes));
  },
};
</script>

<style scoped>
.jumbo {
  background-image: url(https://assets.pga.org.au/pga-aus/2019/08/PGA-Website-FindAPGAPro-1920x500px.jpg);
  height: 500px;
  color: white;
  text-align: center;
}
.jumbo > h1 {
  font-size: 2.5rem;
  margin: 0.2em auto 0;
  line-height: 1;
  width: auto;
  font-weight: 600;
  font-style: normal;
}
.jumbo > .lead {
  margin: 0.3rem auto 2rem;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 100;
}
.jumbotron {
  padding: 8rem 2rem;
}
.input-group {
  width: 40%;
}
.form-group {
  text-align: -webkit-center;
}

.mapCont{
  padding: 0 0 40px 0;
}
.find{
  background-color: #00433e;
  vertical-align: top;
  padding: 4px 10px 4px;
}
</style>
