<template>
  <div id="about">
    <div
      class="splash l"
      v-bind:style="{
        backgroundImage: 'url(' + wp[0].acf.top_block_header + ')',
      }"
    >
      <div class="titleCont">
        <h1>
          <span v-html="wp[0].title.rendered"></span>
        </h1>
      </div>
    </div>
    <b-container class="mainContent"> 
      <span v-html="wp[0].content.rendered"></span>
      <div id="aboutCard" v-if="routeSlug === 'about'">
        <b-row>
          <b-col>
            <div class="box">
              <b-card>
                <b-media right-align>
                  <template #aside>
                    <b-img
                      :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/07/gavheadshot.jpg'"
                      width="100"
                      alt="placeholder"
                    ></b-img>
                  </template>
                  <h5 class="cardTitle">Gavin Kirkman</h5>
                  <h5 class="cardTitle">CEO</h5>
                  <p>
                    <a class="aLinkmail" href="mailto:gkirkman@pga.org.au">gkirkman@pga.org.au</a>
                  </p>
                </b-media>
              </b-card>
            </div>
          </b-col>
          <b-col>
            <div class="box">
              <b-card>
                <b-media right-align>
                  <template #aside>
                    <b-img
                      :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/08/pasted-image-0.png'"
                      width="100"
                      alt="placeholder"
                    ></b-img>
                  </template>
                  <h5 class="cardTitle">Stuart Hergt</h5>
                  <h5 class="cardTitle">COO</h5>
                  <p>
                    <a class="aLinkmail" href="mailto:shergt@pga.org.au">shergt@pga.org.au</a>
                  </p>
                </b-media>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </div>
      <div id="aboutCard" v-if="routeSlug === 'governance'">
        <b-row>
          <b-col
            class="col-md-6 govRow"
            v-for="(edu, index) in Links.slice().reverse()" 
            :key="index"
          >
            <div class="box">
              <b-card @click="$bvModal.show(edu.acf.id)">
                <b-media right-align>
                  <template #aside>
                    <b-img
                      :src="edu.acf.image"
                      width="100"
                      alt="placeholder"
                    ></b-img>
                  </template>
                  <h5 class="cardTitle nameonCard">{{ edu.acf.name }}</h5>
                  <h5 class="cardTitle posonCard">{{ edu.acf.position }}</h5>
                </b-media>
                <b-modal :id="edu.acf.id" hide-header centered hide-footer>

                      <b-img
                        :src="edu.acf.image"
                        blank-color="#ccc"
                        width="250"
                        alt="placeholder"
                        style='float: right;padding: 15px;'
                      ></b-img>

                    <h5 class="modName">{{edu.acf.name}}</h5>
                    <h5 class=modPos>{{ edu.acf.position_2}}</h5>
                    <h5 class='modApp'>{{edu.acf.appointed}}</h5>
                    <span class="descriptModal" v-html="edu.acf.description"></span>
                    <p class="cardEmail">
                      {{ edu.acf.email }}
                    </p>
                    <div class="closeBtn" @click="$bvModal.hide(edu.acf.id)">X</div>
                </b-modal>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </div>
      <div id="aboutCard" v-if="routeSlug === 'media-information'">
        <b-row>
          <b-col class="col-md-6">
            <div class="box">
              <b-card
                class="mediaCentre"
                title="Media Centre"
                :img-src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/08/PGA-Website-Media-MediaCentre-564x312px.jpg'"
                img-alt="Image"
                img-top
                tag="article"
              >
                <b-nav-item
                  to="/about/media-centre"
                >
                  <b-button class="all-butn">Learn More</b-button>
                </b-nav-item>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="routeSlug === 'media-centre'">
        <IntaNews :cat="'50'" />
      </div>
      <div v-if="routeSlug === 'awards'">
        <b-row>
          <b-col
            class="col-md-6 pad awardscard"
            v-for="(edu, index) in Links.slice().reverse()"
            :key="index"
          >
            <div class="box">
              <b-card
                :title="edu.acf.title"
                :img-src="edu.acf.image"
                img-alt="Image"
                img-top
                tag="article"
                class="awardstabs"
              >
                <span class="awardsParagraph" v-html="edu.acf.paragraph"></span>
                <b-nav-item
                  class="learnA"
                  v-if="edu.slug === 'greg-norman-medal'"
                  :to="'/about/'+edu.slug"
                >
                  <b-button>Learn More</b-button>
                </b-nav-item>
                <b-nav-item
                  class="learnA"
                  v-if="edu.slug === 'queensland-golf-industry-awards'"
                 :to="'/about/'+edu.slug"
                >
                  <b-button>Learn More</b-button>
                </b-nav-item>
                <b-nav-item
                  class="learnA"
                  v-if="edu.slug === 'south-australia-golf-industry-awards'"
                  :to="'/about/'+edu.slug"
                >
                  <b-button>Learn More</b-button>
                </b-nav-item>
                <b-nav-item
                  class="learnA"
                  v-if="edu.slug === 'victorian-golf-industry-awards'"
                  :to="'/about/'+edu.slug"
                >
                  <b-button>Learn More</b-button>
                </b-nav-item>
                <b-nav-item
                  class="learnA"
                  v-if="edu.slug === 'western-australia-golf-industry-awards'"
                  :to="'/about/'+edu.slug"
                >
                  <b-button>Learn More</b-button>
                </b-nav-item>
                <b-nav-item
                  class="learnA"
                  v-if="edu.slug === 'new-south-wales-golf-industry-awards'"
                  :to="'/about/'+edu.slug"
                >
                  <b-button>Learn More</b-button>
                </b-nav-item>
                <b-nav-item
                  class="learnA"
                  v-if="
                    edu.slug ===
                      'new-south-wales-act-trainee-graduation-and-awards-dinner-victorian'
                  "
                  :to="'/about/'+edu.slug"
                >
                  <b-button>Learn More</b-button>
                </b-nav-item>
                <b-nav-item
                  class="learnA"
                  v-if="edu.slug === 'pga-trainee-graduation-and-awards-dinner'"
                  :to="'/about/'+edu.slug"
                >
                  <b-button>Learn More</b-button>
                </b-nav-item>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import IntaNews from "@/components/interNews.vue";

export default {
  name: "about",
  props:['slug', 'config'],
  components: {
    IntaNews,
  },
  data() {
    return {
      wp: [],
      Links: [],
    };
  },
    computed: {
    routeSlug: function(route) {
      if (this.slug === undefined) {
        route = this.$route.name;
      } else {
        route = this.slug;
      }
      return route;
    },
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/?slug=" + this.routeSlug)
      .then((response) => {
        this.wp = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/" + this.routeSlug + "/"
        );
      })
      .then((response) => {
        this.Links = response.data;
      });
  },
};
</script>
<style scoped>
::v-deep img.wp-image-29311 {
  text-align: center;
  margin: auto;
  display: block;
}
a.aLinkmail {
  color: #000!important;
}
a.aLinkmail:hover {
  color: #00b588!important;
}
.awardsParagraph {
  color: #000;
}
::v-deep .learnA > a {
  padding-left: 0px;
}
::v-deep .learnA > a > button.btn.btn-secondary{
  padding-top: 10px;
  padding-bottom: 10px;
  background: #01285e;
  border: #01285e;
}
::v-deep .learnA > a > button.btn.btn-secondary:hover{
  padding-top: 10px;
  padding-bottom: 10px;
  background: #2d70c3;
  border: #2d70c3;
  color: #fff;
}
::v-deep .awardstabs > div > h4.card-title{
  color: #01285e;
}
::v-deep .awardstabs > .card-body{
  height: 320px;
  padding-top: 3em;
}
::v-deep .awardscard {
  margin-bottom: 30px;
}
::v-deep .mediaCentre > div > h4 {
  margin-bottom: 0;
  margin-top: 0.8em;
}
::v-deep button.btn.all-butn.btn-secondary {
  background: #01285e;
  border: #01285e;
  color: #fff;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-top: -2em;
  margin-left: -0.9em;
  padding-top: 14px;
}
::v-deep p.job-important {
  margin-bottom: 10px;
}
::v-deep .card-body-non-play {
  padding-top: 30px;
  padding-left: 10px;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  ::v-deep #partners a {
    flex: 0 1 24%;
    padding: 2.5em;
  }
}
.closeBtn:hover {
  position: absolute;
  top: -20px;
  right: -20px;
  background: #04b488;
  color: #fff;
  border: 1px solid #000;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}
.closeBtn {
  position: absolute;
  top: -20px;
  right: -20px;
  background: #fff;
  color: #04b488;
  border: 1px solid #000;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}
::v-deep h5.cardTitle.posonCard {
  font-size: 17px;
}
::v-deep h5.cardTitle.nameonCard {
  color: #01285e;
  font-weight: 700;
  font-size: 15pt;
}
::v-deep span.descriptModal {
  font-size: 17px;
}
::v-deep img.wp-image-11471 {
  max-width: 100%;
  text-align: center;
  margin: auto;
  display: block;
}
#about {
  background-color: #f9f9f9;
}
.titleCont {
  position: relative;
  top: 49%;
  transform: translateY(-50%);
  text-align: center;
}
.splash {
  text-align: center;
  color: white;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 550px;
  height: 275px;
}
.mainContent {
  padding: 100px 0;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
  /* color: #00b588; */
  cursor: pointer;
}
.cardTitle {
  padding: 2.5px 0;
}
.cardEmail {
  padding: 10px 0;
}
#aboutCard {
  padding: 45px 0;
}
.govRow {
  padding: 10px;
}
.btn {
  background-color: #00433e;
  border: 1px solid #00433e;
}
.btn:hover {
  background-color: white;
  color: #00433e;
  border: 1px solid #00433e;
}
.modName{
    color: #00433e;
    font-weight: 700;
    font-size: 25pt;
    margin-top: 0;
}
.modPos{
    color: #00b588;
    font-size: 15pt;
    font-weight: 700;
}
.modApp{
      color: #00b588;
    font-size: 15pt;
    font-weight: 700;
}
::v-deep .modal-body{
  padding: 20px;
}
@media only screen and (max-width: 768px) {
  .mainContent {
    padding: 15px;
  }
}
@media (min-width: 768px){
  ::v-deep .modal-dialog{
    width: 700px!important;
    max-width: 100%;
  }
}
</style>
