<template>
  <b-container class="newslist">
      <div class="ad">
        <div class="ad" style="text-align:center;">
          <script
            async
            src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            type="application/javascript"
          ></script>
          <script type="application/javascript">
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(function() {
              billboardsizes = googletag
                .sizeMapping()
                .addSize([980, 0], [728, 90])
                .addSize([0, 0], [320, 50])
                .build(); 
              googletag
                .defineSlot(
                  "/2835490/New_PGA_Top_Banner_Home",
                  [
                    [970, 250],
                    [320, 50],
                    [728, 90],
                  ],
                  "div-gpt-ad-1568947342181-0"
                )
                .defineSizeMapping(billboardsizes)
                .addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
            });
          </script>

          <!-- /2835490/New_PGA_Top_Banner_Home -->
          <div id="div-gpt-ad-1568947342181-0">
            <script type="application/javascript">
              googletag.cmd.push(function() {
                googletag.display("div-gpt-ad-1568947342181-0");
              });
            </script>
          </div>
        </div>
      </div>
    <div id="secOne">
      <h4 class="title">
        Latest News
      </h4>
      <IntaNews :cat="latestFull" :featHome="featHome" />
    </div>
    <div id="secTwo">
      <b-row>
        <b-col class="col-md-4">
          <a v-bind:href="'/whats-on-this-week/'">
            <img class="sectionTwoimages box" v-bind:src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/07/PGA-Website-NewsButtons-WhatsOn-350x100px.jpg'">
          </a>
        </b-col>
        <b-col class="col-md-4">
          <a v-bind:href="'/whats-on-this-week/'">
            <img class="sectionTwoimages box" v-bind:src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/07/PGA-Website-NewsButtons-AussieTour-350x100px.jpg'">
          </a>
        </b-col>
        <b-col class="col-md-4">
          <a v-bind:href="'https://www.golf.org.au/aussies-on-tour-dec-7'">
            <img class="sectionTwoimages box" v-bind:src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/07/PGA-Website-NewsButtons-WorldRankings-350x100px.jpg'">
          </a>
        </b-col>
      </b-row>
    </div>
    <div id="secThree">
      <h4 class="title">
        ISPS HANDA PGA Tour of Australasia
      </h4>
      <IntaNews :cat="isps" :featHome="featHome" />
    </div>
    <div class="ad" style="text-align:center;">
        <div class="ad" style="text-align:center;">
          <script
            async
            src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            type="application/javascript"
          ></script>
          <script type="application/javascript">
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(function() {
              billboardsizes = googletag
                .sizeMapping()
                .addSize([980, 0], [728, 90])
                .addSize([0, 0], [320, 50])
                .build();
              googletag
                .defineSlot(
                  "/2835490/New_PGA_Top_Banner_BB",
                  [
                    [970, 250],
                    [320, 50],
                    [728, 90],
                  ],
                  "div-gpt-ad-1567045864014-0"
                )
                .defineSizeMapping(billboardsizes)
                .addService(googletag.pubads());
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
            });
          </script>

          <!-- /2835490/New_PGA_Top_Banner_Home -->
          <div id='div-gpt-ad-1567045864014-0'>
            <script type="application/javascript">
              googletag.cmd.push(function() { googletag.display('div-gpt-ad-1567045864014-0'); });
            </script>
          </div>
        </div>
    </div>
    <div id="secFour">
      <h4 class="title">
        International News
      </h4>
      <IntaNews :cat="inter" :featHome="featHome" />
    </div>
    <div class="ad" style="text-align:center;">
      <div class="ad" style="text-align:center;">
        <script
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          type="application/javascript"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            billboardsizes = googletag
              .sizeMapping()
              .addSize([980, 0], [728, 90])
              .addSize([0, 0], [320, 50])
              .build();
            googletag
              .defineSlot(
                "/2835490/New_PGA_Tier3_Banner",
                [
                  [970, 250],
                  [320, 50],
                  [728, 90],
                ],
                "div-gpt-ad-1568956528388-0"
              )
              .defineSizeMapping(billboardsizes)
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
          });
        </script>

        <!-- /2835490/New_PGA_Top_Banner_Home -->
        <div id='div-gpt-ad-1568956528388-0'>
          <script type="application/javascript">
            googletag.cmd.push(function() { googletag.display('div-gpt-ad-1568956528388-0'); });
          </script>
        </div>
      </div>
    </div>
    <div id="secFive">
      <h4 class="title">
        adidas PGA Pro-Am Series
      </h4>
      <IntaNews :cat="proam" :featHome="featHome" />
    </div>
    <div id="secSix">
      <h4 class="title">
        SParms PGA Legends Tour
      </h4>
      <IntaNews :cat="legends" :featHome="featHome" />
    </div>
    <div class="ad" style="text-align:center;">
      <div class="ad" style="text-align:center;">
        <script
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          type="application/javascript"
        ></script>
        <script type="application/javascript">
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            billboardsizes = googletag
              .sizeMapping()
              .addSize([980, 0], [728, 90])
              .addSize([0, 0], [320, 50])
              .build();
            googletag
              .defineSlot(
                "/2835490/New_PGA_Lower_Banner",
                [
                  [970, 250],
                  [320, 50],
                  [728, 90],
                ],
                "div-gpt-ad-1568956585834-0"
              )
              .defineSizeMapping(billboardsizes)
              .addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
          });
        </script>

        <!-- /2835490/New_PGA_Top_Banner_Home -->
        <div id='div-gpt-ad-1568956585834-0'>
          <script type="application/javascript">
            googletag.cmd.push(function() { googletag.display('div-gpt-ad-1568956585834-0'); });
          </script>
        </div>
      </div>
    </div>
    <div id="secSeven">
      <h4 class="title">
        The 19th
      </h4>
      <IntaNews :cat="nineteen" :featHome="featHome" />
    </div>
  </b-container>
</template>

<script>
import IntaNews from "@/components/interNews.vue";
export default {
  name: "posts",
  components: {
    IntaNews,
  },
  props:['config'],
  data() {
    return {
      posts: [],
      inter: "5",
      isps: '17',
      proam:'11',
      pro: "44",
      legends:'32',
      latestsm: "29",
      latestFull: "8",
      nineteen: '38',
      featHome: "false",
    };
  },
};
</script>

<style scoped>
div#secTwo {
  margin-top: 2em;
  margin-bottom: 4em;
}
.sectionTwoimages {
  width: 100%;
  border-radius: 5px;
}
.newslist {
  margin-top: 2em;
  margin-bottom: 2em;
}
.ad {
  padding: 15px 0; 
}
.title {
  font-weight: 500;
  font-family: "proxima-nova, sans-serif", sans-serif !important;
  color: #000;
  font-size: 23pt;
  margin-top: 1em;
  line-height: 1.1;
  letter-spacing: 2px;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
}
</style>
