<template>
  <div>
    <b-row>
      <b-col lg="4" v-for="(vids, index) in this.cat.items" :key="index">
        <div class="box" @click="$bvModal.show(vids.etag)">
          <b-card
            :title="vids.snippet.title"
            :img-src="vids.snippet.thumbnails.high.url"
            img-alt="Image"
            img-top
            tag="article"
            class="feat"
            ><b-img
              class="play"
              :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/02/play.png'"
            ></b-img>
          </b-card>
          <b-modal :id="vids.etag" hide-header centered hide-footer>
            <b-embed
              type="iframe"
              aspect="16by9"
              :src="
                'https://www.youtube.com/embed/' +
                  vids.snippet.resourceId.videoId
              "
              allowfullscreen
            ></b-embed>
          </b-modal>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "youTube",
  props: ["cat", 'config'],
};
</script>

<style scoped>
.row {
  padding-bottom: 20px;
}
.col {
  padding-bottom: 20px;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  margin-bottom: 2em;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
  color: #00b588;
  -webkit-box-shadow: rgb(45 45 45 / 5%) 0 2px 2px, rgb(49 49 49 / 5%) 0 4px 4px, rgb(42 42 42 / 5%) 0 8px 8px, rgb(32 32 32 / 5%) 0 16px 16px, rgb(49 49 49 / 5%) 0 32px 32px, rgb(35 35 35 / 5%) 0 64px 64px;
  box-shadow: 0 2px 2px rgb(45 45 45 / 5%), 0 4px 4px rgb(49 49 49 / 5%), 0 8px 8px rgb(42 42 42 / 5%), 0 16px 16px rgb(32 32 32 / 5%), 0 32px 32px rgb(49 49 49 / 5%), 0 64px 64px rgb(35 35 35 / 5%);
  -webkit-transform: translate3D(0,-4px,0);
  transform: translate3D(0,-2px,0);
}
.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  max-height: 100%;
  border-radius: 0 0 2px 2px;
  font-size: 15pt !important;
  line-height: 40px;
}
::v-deep .modal-content {
  background-color: transparent;
  border: 0;
}
::v-deep .modal-body {
  padding: 0;
}
.embed-responsive {
  width: 200%;
  margin-left: -300px;
}
.play {
  position: absolute;
  width: 120px;
  height: 120px;
  margin: -280px 0 0 100px;
  z-index: 11;
}
@media only screen and (max-width: 1024px) {
  .play[data-v-31b8cfe3] {
    width: 90px;
    height: 90px;
    margin: -193px 0 0 59px;
  }
}
@media only screen and (max-width: 768px) {
  .play {
    display: none;
  }
  .embed-responsive[data-v-31b8cfe3] {
    width: 150%;
    margin-left: -125px;
  }
  .card {
    margin: 20px 0;
  }
}
@media only screen and (max-width: 480px) {
  .embed-responsive[data-v-31b8cfe3] {
    width: 100%;
    margin-left: 0;
  }
}
</style>
