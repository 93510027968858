<template>
  <div id="proTable">
    <b-row>
      <b-col class="col-md-4 spaceBelow" v-for="(pro, index) in pros" :key="index">
        <b-card tag="article" class="mb-2">
          <b-row>
            <b-col>
              <b-row>
                <b-col>
                  <h3 class="namePro">{{ pro.forename }} {{ pro.surname }}</h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p class="cert">
                    {{ pro.certs }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="outerProImg">
                <b-img class="proImg" :src="pro.avatar"></b-img>
              </div>
            </b-col>
            <b-col>
              <b-img
                class="golfAus"
                src="https://assets.pga.org.au/pga-aus/2021/03/PGA_MEM_PRO_LOGO_CMYK_POS.png"
              ></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                {{ pro.title }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                {{ pro.vname }}
              </p>
            </b-col>
          </b-row>
          <b-button v-b-toggle="pro._id">Pro Details</b-button>
        </b-card>
        <b-sidebar :id="pro._id" :title="pro.forename + ' ' + pro.surname">
          <div class="py-2">
            <div class="grey">
              <b-row>
                <b-col>
                  <b-img :src="pro.avatar" fluid thumbnail></b-img>
                </b-col>
                <b-col>
                  <b-img
                    class="golfAus"
                    src="https://assets.pga.org.au/pga-aus/2021/03/PGA_MEM_PRO_LOGO_CMYK_POS.png"
                  ></b-img>
                  <div v-if="pro.certs === 'Certified PGA Professional'">
                    <b-button
                      class="certPop"
                      v-b-popover.hover.top="
                        'Designates that a Member has completed the required amount of general continuing education to indicate that they are remaining current with overall golf industry trends.'
                      "
                      :title="pro.certs"
                    >
                      {{ pro.certs }}
                    </b-button>
                  </div>
                  <div v-if="pro.certs === 'PGA Professional'">
                    <b-button
                      class="certPop"
                      v-b-popover.hover.top="
                        'A PGA Member who does not hold a minimum of 4 total ACE Credits across all of the ACE Streams will be referred to as a PGA Professional.'
                      "
                      :title="pro.certs"
                    >
                      {{ pro.certs }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p class="sideTitle">
                    {{ pro.title }}
                  </p>
                </b-col>
              </b-row>
            </div>
            <div class="white">
              <b-row>
                <b-col>
                  <p>
                    Contact:
                  </p>
                </b-col>
                <b-col>
                  <a :href="'tel:' +  pro.phone" v-show="pro.phone.length > 0"> 
                    <b-button class="contact">{{ pro.phone }}</b-button>
                  </a>
                  <a :href="'mailto:' + pro.email" v-show="pro.email.length > 0">
                    <b-button class="contact">Email</b-button>
                  </a>
                </b-col>
              </b-row>
            </div>
            <div class="grey">
              <b-row>
                <b-col>
                  <p
                    v-if="
                      pro.cert1 !== '' ||
                        pro.cert2 !== '' ||
                        pro.cert3 !== '' ||
                        pro.cert4 !== ''
                    "
                  >
                    Advanced:
                  </p>
                </b-col>
                <b-col>
                  <p>
                    <span>{{ pro.cert1 }}</span
                    ><span>{{ pro.cert2 }}</span
                    ><span>{{ pro.cert3 }}</span
                    ><span>{{ pro.cert4 }}</span>
                  </p>
                </b-col>
              </b-row>
              <div v-if="pro.prog1 !== '' ||
                      pro.prog2 !== '' ||
                      pro.prog3 !== '' ||
                      pro.prog4 !== '' ||
                      pro.prog5 !== '' ||
                      pro.prog6 !== '' ||
                      pro.service1 !== ''||
                      pro.service2 !== ''||
                      pro.service3 !== ''||
                      pro.service4 !== ''||
                      pro.service5 !== ''||
                      pro.service6 !== ''||
                      pro.service7 !== ''||
                      pro.service8 !== ''">
                <b-row v-if="pro.prog1 !== '' ||
                      pro.prog2 !== '' ||
                      pro.prog3 !== '' ||
                      pro.prog4 !== '' ||
                      pro.prog5 !== '' ||
                      pro.prog6 !== '' ||
                      pro.service1 !== ''||
                      pro.service2 !== ''||
                      pro.service3 !== ''||
                      pro.service4 !== ''||
                      pro.service5 !== ''||
                      pro.service6 !== ''||
                      pro.service7 !== ''||
                      pro.service8 !== ''">
                  <b-col>
                    <p>
                      Coaching:
                    </p>
                    <b-row>
                      <b-col>
                        <ul>
                          <li class="lists servicesList" v-if="pro.service1 !== ''">
                            {{ pro.service1 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.service2 !== ''">
                            {{ pro.service2 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.service3 !== ''">
                            {{ pro.service3 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.service4 !== ''">
                            {{ pro.service4 }}
                          </li>
                        </ul>
                      </b-col>
                      <b-col>
                        <ul>
                          <li class="lists servicesList" v-if="pro.service5 !== ''">
                            {{ pro.service5 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.service6 !== ''">
                            {{ pro.service6 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.service7 !== ''">
                            {{ pro.service7 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.service8 !== ''">
                            {{ pro.service8 }}
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row
                  v-if="
                    pro.prog1 !== '' ||
                      pro.prog2 !== '' ||
                      pro.prog3 !== '' ||
                      pro.prog4 !== '' ||
                      pro.prog5 !== '' ||
                      pro.prog6 !== ''
                  "
                >
                  <b-col>
                    <b-row>
                      <b-col>
                        <p>
                          Programms:
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <ul>
                          <li class="lists servicesList" v-if="pro.prog1 !== ''">
                            {{ pro.prog1 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.prog2 !== ''">
                            {{ pro.prog2 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.prog3 !== ''">
                            {{ pro.prog3 }}
                          </li>
                        </ul>
                      </b-col>
                      <b-col>
                        <ul>
                          <li class="lists servicesList" v-if="pro.prog4 !== ''">
                            {{ pro.prog4 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.prog5 !== ''">
                            {{ pro.prog5 }}
                          </li>
                          <li class="lists servicesList" v-if="pro.prog6 !== ''">
                            {{ pro.prog6 }}
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
              <b-row v-else>
                <b-col class="noProg">
                  Please Contact {{ pro.forename }} For More Information
                </b-col>
              </b-row>
            </div>
            <div class="white">
              <p class="address">
                <a :href="'http://' + pro.website" target="_blank">
                  {{ pro.vname }}
                </a>
              </p>
              <p class="lists address">
                {{ pro.addr1 }} {{ pro.city }} {{ pro.postcode }}
              </p>
              <b-row>
                <b-col>
                  <div class="mapouter">
                    <div class="gmap_canvas">
                      <iframe
                        id="gmap_canvas"
                        :src="
                          'https://maps.google.com/maps?q=' +
                            pro.vname +
                            ' ' +
                            pro.addr1 +
                            '&t=&z=13&ie=UTF8&iwloc=&output=embed'
                        "
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe
                      ><a href="https://yt2.org"></a><br />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-sidebar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "proTable",
  props: ["pros"],
  data() {
    return {
      isHidden: false,
    };
  },
  methods: {
    toggle() {
      this.isHidden = !this.isHidden;
    },
  },
};
</script>

<style scoped>
.spaceBelow {
  margin-bottom: 1.5em;
}
.card-body {
  height: 400px;
}
li.servicesList {
  text-align: left;
}
#proTable {
  text-align: center;
  margin-top: 2em;
}
.golfAus {
  width: 107px;
}
.outerProImg {
  display: inline-block;
  margin: 0 0.1rem;
  width: 116px;
  height: 118px;
  border-radius: 50%;
  overflow: hidden;
}
.proImg {
  width: 100%;
}
.namePro {
  color: #3b3b3b;
  font-weight: bold;
}
.cert {
  font-weight: bold;
  color: #000;
  margin-bottom: 0;
  font-size: 17px;
}
/* SideBar */
::v-deep .b-sidebar {
  width: 400px;
}
::v-deep .b-sidebar-header {
  background-color: #00433e;
  color: white;
  padding: 20px 15px;
}
::v-deep .b-icon.bi {
  color: white;
}
.contact {
  background-color: #00433e;
  width: 80%;
  margin: 5px 0;
}
.lists {
  font-size: 16px;
}
.certPop {
  background-color: transparent;
  color: black;
  border: 0;
  font-size: 14px;
  padding: 0;
}
.sideTitle {
  padding-top: 5px;
}
.noProg {
  padding: 50px 40px;
}
.white {
  background-color: white;
  padding: 10px;
}
.grey {
  background-color: #f9f9f9;
  padding: 10px;
}
.address {
  margin-bottom: 0;
}

@media only screen and (max-width: 500px) {
  .card-body {
    height: auto;
  }
}
</style>
